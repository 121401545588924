import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button, Modal, CircularProgress } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HistoryIcon from '@mui/icons-material/History';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useParams, useNavigate } from "react-router-dom";
import ReactECharts from 'echarts-for-react';

function HouseMonitoringWelcomeMsg({ houseAddress, currentCost, costData }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const { id } = useParams(); // Get house ID from route parameters
    const navigate = useNavigate(); // Hook to navigate programmatically
    const [open, setOpen] = useState(false);
    const [chartLoaded, setChartLoaded] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Ensure costData is fully loaded before rendering the chart
        if (costData && costData.labels && costData.labels.length > 0) {
            setChartLoaded(true);
        }
    }, [costData]);

    const currentDate = currentTime.toLocaleDateString();

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: 'rgba(10, 107, 172, 0.2)',
        borderRadius: '25px',
        width: '100%'
    };

    const handleEditClick = () => {
        navigate(`/edit/house/${id}`);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const chartOptions = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return params.value.toFixed(2) + " €";
            }
        },
        xAxis: {
            type: 'category',
            data: costData?.labels || []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: costData?.costs || [],
                type: 'bar'
            }
        ]
    };

    return (
        <>
            <Paper
                elevation={0}
                style={{
                    padding: '20px',
                    borderRadius: '25px',
                    backgroundColor: 'white',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h4">Welcome home!</Typography>
                <Typography variant="subtitle2" gutterBottom mb={2}>
                    {houseAddress}
                </Typography>

                {/* Edit House Details Button */}
                <Button className={'fancyButton'}
                        variant="contained"
                        onClick={handleEditClick}
                        startIcon={<EditNoteIcon />}
                        sx={{ mb: 2, width: 'fit-content' }}
                >
                    Edit house details
                </Button>

                <Grid container spacing={2} sx={{ mt: 'auto' }}>
                    <Grid item xs={12}>
                        <Box style={boxStyle}>
                            <Typography variant="subtitle2">Current Electricity Cost</Typography>
                            {currentCost && <Typography variant={'h2'} className={'fancyText'} fontWeight={600}>
                                {currentCost} €
                            </Typography>}
                            <Typography variant="body2" textAlign="center">
                                This is your real-time electricity cost. Monitor and optimize to save energy!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" className={'fancyButton'} onClick={handleOpen}
                                startIcon={<HistoryIcon />}>
                            Previous Months' Costs
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Modal open={open} onClose={handleClose}>
                <Box style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                }}>
                    <Typography variant="h6" gutterBottom>
                        Previous Months' Costs
                    </Typography>

                    {/* Show loading spinner if chart data isn't loaded */}
                    {!chartLoaded ? (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <ReactECharts option={chartOptions} />
                    )}

                    <Button onClick={handleClose} className={'fancyButton'} variant="contained" sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
}

export default HouseMonitoringWelcomeMsg;
