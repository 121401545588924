import React from 'react';
import ReactECharts from 'echarts-for-react';
import {
    Grid,
    Typography,
    Paper,
    Box,
    Skeleton,
    Alert,
    AlertTitle,
    Tooltip,
    IconButton,
    LinearProgress
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function HouseMonitoringAdditional({loadingInsights, dayInsights, weekInsights, currentCost, averageCost}) {
    // Percentage calculations
    const todayPercentage = (dayInsights?.data?.current / dayInsights?.data?.average) * 100;
    const weeklyPercentage = (weekInsights?.data?.current / weekInsights?.data?.average) * 100;

    // ⚡ Static values for electricity cost (since API data is not ready yet)
    const costPercentage = (currentCost / averageCost) * 100; // Static percentage calculation

    // Gradient colors for pie charts
    const gradientColor = {
        measured: {
            type: 'linear',
            x: 0, y: 0, x2: 1, y2: 1,
            colorStops: [
                {offset: 0, color: '#00ABE1'}, // Light blue
                {offset: 1, color: '#161F6D'}  // Dark blue
            ]
        },
        remaining: '#E0E0E0'
    };

    // Today's Consumption Pie Chart
    const todayDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: params => params.name === 'Consumption' ? `Consumption today vs Average Daily Consumption: ${params.value}%` : ''
        },
        series: [
            {
                name: "Today's Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: todayPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - todayPercentage).toFixed(2),
                        name: 'Daily Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };

    // Weekly Consumption Pie Chart
    const weeklyDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: params => params.name === 'Consumption' ? `Consumption this week vs Average Weekly Consumption: ${params.value}%` : ''
        },
        series: [
            {
                name: "Weekly Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: weeklyPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - weeklyPercentage).toFixed(2),
                        name: 'Weekly Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };


    return (
        <Paper
            elevation={0}
            style={{
                padding: '20px',
                borderRadius: '25px',
                backgroundColor: 'white',
                height: '100%',
            }}
        >
            {/* General Title */}
            <Typography
                variant="h5"
                align="center"
                sx={{fontWeight: 'bold'}}
                className={'fancyText'}
            >
                Consumption Overview
            </Typography>

            <Grid container spacing={1} alignItems="center" sx={{height: '100%'}}>
                {/* Today's Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {dayInsights?.error ? (
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong with today's data. Please try again later.
                        </Alert>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReactECharts option={todayDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                            {loadingInsights ? (
                                <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: '25px'}}/>
                            ) : (
                                <Typography align="center" variant="body1" display="flex" alignItems="center">
                                    <strong>{dayInsights.data.current} kWh &nbsp;</strong>
                                    (
                                    <strong style={{
                                        color: todayPercentage > 80 ? '#d32f2f' : todayPercentage > 50 ? '#ED6C02' : 'inherit',
                                    }}>
                                        {todayPercentage.toFixed(1)}%
                                    </strong> &nbsp;of average)
                                    <Tooltip
                                        title="Consumption today compared to last month's average daily consumption"
                                        arrow>
                                        <IconButton size="small">
                                            <InfoOutlinedIcon fontSize="small" sx={{color: '#00ABE1'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            )}
                        </Box>
                    )}
                </Grid>

                {/* Weekly Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {weekInsights?.error ? (
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong with this week's data. Please try again later.
                        </Alert>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReactECharts option={weeklyDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                            {loadingInsights ? (
                                <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: '25px'}}/>
                            ) : (
                                <Typography align="center" variant="body1" display="flex" alignItems="center">
                                    <strong>{weekInsights.data.current} kWh &nbsp;</strong>
                                    (
                                    <strong style={{
                                        color: weeklyPercentage > 80 ? '#d32f2f' : weeklyPercentage > 50 ? '#ED6C02' : 'inherit',
                                    }}>
                                        {weeklyPercentage.toFixed(1)}%
                                    </strong> &nbsp;of average)
                                    <Tooltip
                                        title="Consumption this week compared to last week's average total consumption"
                                        arrow>
                                        <IconButton size="small">
                                            <InfoOutlinedIcon fontSize="small" sx={{color: '#00ABE1'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            )}
                        </Box>
                    )}
                </Grid>

                {/* Electricity Cost Progress Bar (Static Data) */}
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                        style={{
                            padding: '20px',
                            borderRadius: '25px',
                            backgroundColor: '#fff',
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '20px'
                        }}
                    >
                        {averageCost && <>
                            <Typography variant="h5" align="center" gutterBottom sx={{fontWeight: 'bold'}}
                                        className={'fancyText'}>
                                Current Electricity Cost
                            </Typography>

                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.min(costPercentage, 100)}
                                    sx={{
                                        width: '100%',
                                        height: 12,
                                        borderRadius: 6,
                                        backgroundColor: 'rgba(0, 171, 225, 0.2)',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundImage: `linear-gradient(to right, #00ABE1, #161F6D)`,
                                        },
                                    }}
                                />
                                {currentCost && <Typography variant="body1" align="center" sx={{mt: 2}}>
                                    Electricity cost until today is <strong>€{currentCost}</strong>,
                                    average <strong>€{averageCost}</strong>
                                    <Tooltip title="Current electricity cost vs last month's cost" arrow>
                                        <IconButton size="small">
                                            <InfoOutlinedIcon fontSize="small" sx={{color: '#00ABE1'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>}
                            </Box>
                        </>}
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default HouseMonitoringAdditional;
