import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {addHouse, getHouseEntity, updateHouse} from "../../api_calls/api_calls_houses";
import {useRefreshHouses} from "../../hooks/useRefreshHouses";
import axios from 'axios'

import {
    Container, Grid, Paper, Typography,
    Divider, FormControl, InputLabel,
    Select, MenuItem, Stack, Alert,
    TextField, Snackbar, Box, Button
} from "@mui/material";

import LoadingFullPage from "../../components/layout/LoadingFullPage";
import {useKeycloak} from "@react-keycloak/web";

const HouseForm = () => {
    const {id} = useParams();
    const isEditMode = Boolean(id);
    const [invoices, setInvoices] = useState()
    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [keycloak, initialized]);

    useEffect(() => {
        document.title = isEditMode ? 'Edit House | Energy Home System' : 'Add House | Energy Home System';
    }, [isEditMode]);

    useEffect(() => {
        axios.get('/invoice/getallcurrentmonth')
            .then(response => {
                setInvoices(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const {refreshHouses} = useRefreshHouses();
    const navigate = useNavigate();

    const initialFormState = {
        address: '',
        typeHouse: '',
        area: '',
        year: '',
        floor: '',
        energyClass: '',
        heatingType: '',
        coolingType: '',
        invoiceProvidername: '',
        invoicename: '',
    };

    const [formValues, setFormValues] = useState(initialFormState);
    const [formErrors, setFormErrors] = useState({});
    const [backDrop, setBackDrop] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [houses, setHouses] = useState([]);

    useEffect(() => {
        if (isEditMode && initialized) {
            getHouseEntity(id)
                .then(response => {
                    setFormValues({
                        address: response.address,
                        typeHouse: response.typehouse,
                        area: response.area.toString(),
                        year: response.year.toString(),
                        floor: response.floor,
                        energyClass: response.energyclass,
                        heatingType: response.heatingtype,
                        coolingType: response.coolingtype,
                        invoiceProvidername: response.invoiceprovidername,
                        invoicename: response.invoicename,
                    });
                })
                .catch(error => {
                    setFailure(true);
                });
        }
    }, [id, initialized, isEditMode]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Special handling for invoice selection
        if (name === "invoice") {
            const [invoiceProvidername, invoicename] = value.split("-");
            setFormValues({
                ...formValues,
                invoiceProvidername,
                invoicename
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }

        setFormErrors({
            ...formErrors,
            [name]: false
        });
    };

    const handleCloseSnackbar = () => {
        setFailure(false);
        navigate('/houses');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validateForm(formValues);
        if (Object.keys(errors).length === 0) {
            setBackDrop(true);
            const house = {
                name: 'My House',
                ...formValues,
                area: parseInt(formValues.area),
                year: parseInt(formValues.year)
            };

            console.log(house)
            if (isEditMode) {
                updateHouse(id, house)
                    .then(() => {
                        setBackDrop(false);
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(false);
                            navigate('/houses');
                        }, 3000);
                    })
                    .catch(() => {
                        setBackDrop(false);
                        setFailure(true);
                    });
            } else {
                addHouse(house)
                    .then(() => {
                        setBackDrop(false);
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(false);
                            navigate('/houses');
                        }, 3000);
                    })
                    .catch(() => {
                        setBackDrop(false);
                        setFailure(true);
                    });
            }
        } else {
            setFormErrors(errors);
        }
    };

    const validateForm = (values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
            if (values[key] === '') {
                errors[key] = true;
            }
            if (key === 'year' && (values[key] === '' || parseInt(values[key]) > 2023)) {
                errors[key] = true;
            }
        });
        return errors;
    };

    useEffect(() => {
        refreshHouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [houses]);

    return (
        <>
            {allowed && <>
                <Container>
                    <Paper sx={{p: 3, mt: 5}}>
                        <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>
                            {isEditMode ? 'Edit House' : 'Add House'}
                        </Typography>
                        <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-basic"
                                        label="Address"
                                        variant="outlined"
                                        placeholder="Insert house address"
                                        error={formValues.address === '' && formErrors.address}
                                        onChange={handleChange}
                                        name="address"
                                        value={formValues.address}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl required fullWidth
                                                 error={formValues.typeHouse === '' && formErrors.typeHouse}>
                                        <InputLabel id="typeHouse-label">Type of building</InputLabel>
                                        <Select
                                            labelId="typeHouse-label"
                                            id="typeHouse"
                                            value={formValues.typeHouse}
                                            label="Type of building"
                                            onChange={handleChange}
                                            name="typeHouse"
                                        >
                                            <MenuItem value='Home'>Home</MenuItem>
                                            <MenuItem value='Office'>Office</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="area"
                                        label="Square meters"
                                        variant="outlined"
                                        placeholder="Insert area"
                                        type="number"
                                        error={formValues.area === '' && formErrors.area}
                                        InputProps={{inputProps: {min: 0}, fullWidth: true}}
                                        onChange={handleChange}
                                        name="area"
                                        value={formValues.area}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="year"
                                        label="Year of construction"
                                        variant="outlined"
                                        placeholder="Insert year of construction"
                                        type="number"
                                        error={(formValues.year === '' || parseInt(formValues.year) > 2023) && formErrors.year}
                                        InputProps={{inputProps: {min: 1900, max: 2023}, fullWidth: true}}
                                        onChange={handleChange}
                                        name="year"
                                        value={formValues.year}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl required fullWidth error={formValues.floor === '' && formErrors.floor}>
                                        <InputLabel id="floor-label">Floor</InputLabel>
                                        <Select
                                            labelId="floor-label"
                                            id="floor"
                                            value={formValues.floor}
                                            label="Floor"
                                            onChange={handleChange}
                                            name="floor"
                                        >
                                            <MenuItem value='Basement'>Basement</MenuItem>
                                            <MenuItem value='Ground floor'>Ground floor</MenuItem>
                                            <MenuItem value='Greater than 1'>Greater than 1</MenuItem>
                                            <MenuItem value='Roof'>Roof</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl required fullWidth
                                                 error={formValues.energyClass === '' && formErrors.energyClass}>
                                        <InputLabel id="energyClass-label">Energy Class</InputLabel>
                                        <Select
                                            labelId="energyClass-label"
                                            id="energyClass"
                                            value={formValues.energyClass}
                                            label="Energy Class"
                                            onChange={handleChange}
                                            name="energyClass"
                                        >
                                            <MenuItem value='A+'>A+</MenuItem>
                                            <MenuItem value='A'>A</MenuItem>
                                            <MenuItem value='B+'>B+</MenuItem>
                                            <MenuItem value='B'>B</MenuItem>
                                            <MenuItem value='C'>C</MenuItem>
                                            <MenuItem value='D'>D</MenuItem>
                                            <MenuItem value='E'>E</MenuItem>
                                            <MenuItem value='F'>F</MenuItem>
                                            <MenuItem value='H'>Z</MenuItem>
                                            <MenuItem value='No Energy Certificate.'>No Energy Certificate.</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl required fullWidth
                                                 error={formValues.heatingType === '' && formErrors.heatingType}>
                                        <InputLabel id="heatingType-label">Heating Type</InputLabel>
                                        <Select
                                            labelId="heatingType-label"
                                            id="heatingType"
                                            value={formValues.heatingType}
                                            label="Heating Type"
                                            onChange={handleChange}
                                            name="heatingType"
                                        >
                                            <MenuItem value='Oil boiler'>Oil boiler</MenuItem>
                                            <MenuItem value='Natural Gas Boiler'>Natural Gas Boiler</MenuItem>
                                            <MenuItem value='Pump'>Pump</MenuItem>
                                            <MenuItem value='Electric device'>Electric device</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl required fullWidth
                                                 error={formValues.coolingType === '' && formErrors.coolingType}>
                                        <InputLabel id="coolingType-label">Cooling Type</InputLabel>
                                        <Select
                                            labelId="coolingType-label"
                                            id="coolingType"
                                            value={formValues.coolingType}
                                            label="Cooling Type"
                                            onChange={handleChange}
                                            name="coolingType"
                                        >
                                            <MenuItem value='Split units'>Split units</MenuItem>
                                            <MenuItem value='Central unit'>Central unit</MenuItem>
                                            <MenuItem value='Fan'>Fan</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="invoice-label">Invoice</InputLabel>
                                        <Select
                                            labelId="invoice-label"
                                            id="invoice"
                                            value={`${formValues.invoiceProvidername}-${formValues.invoicename}`}
                                            label="Invoice"
                                            onChange={handleChange}
                                            name="invoice"
                                        >
                                            {invoices?.length > 0 && invoices.map((invoiceData, index) => {
                                                const { provider_name, invoice } = invoiceData;

                                                // Color mapping
                                                const colorMap = {
                                                    green: "rgba(0, 128, 0, 0.6)",
                                                    blue: "rgba(0, 0, 255, 0.6)",
                                                    yellow: "rgba(255, 255, 0, 0.6)"
                                                };
                                                const backgroundColor = colorMap[invoice.color] || "rgba(200, 200, 200, 0.6)"; // Default color

                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={`${provider_name}-${invoice.name}`} // Unique value for selection
                                                        style={{
                                                            backgroundColor,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "10px"
                                                        }}
                                                    >
                                                        <img
                                                            src={`/images/providers/${provider_name}.png`} // Corrected URL
                                                            alt={`${provider_name} logo`}
                                                            style={{ width: 30, height: 30 }}
                                                        />
                                                        {invoice.Provider.display_name} - {invoice.display_name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid sx={{marginX: 'auto'}} item xs={12}>
                                <Box sx={{mt: 5, mb: 2}}>
                                    <Button type="submit" fullWidth variant="contained" color="middle">
                                        <Typography variant="small"
                                                    sx={{color: 'white'}}>{isEditMode ? 'EDIT' : 'ADD'}</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    </Paper>
                </Container>
                <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>
                <Stack spacing={2} sx={{width: '100%'}}>
                    <Snackbar open={success} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                        <Alert variant='filled' onClose={handleCloseSnackbar} severity="success">
                            {isEditMode ? 'The house has been successfully updated.' : 'The house has been successfully added.'}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={failure} autoHideDuration={3000} onClose={() => setFailure(false)}>
                        <Alert variant='filled' onClose={() => setFailure(false)} severity="error">
                            Something went wrong! Please try again.
                        </Alert>
                    </Snackbar>
                </Stack>
            </>}
        </>
    );
}

export default HouseForm;
